body {
	line-height: 1.4;
	color: #444;
	font-family: "Helvetica Neue",HelveticaNeue,Arial,sans-serif;
}

header, nav, article {
	display: block;
}

header {
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .header-image-wrapper.contain {
    height: 250px;
  }
}

@media screen and (max-width: 480px) {
  .header-image-wrapper.contain {
    height: 200px;
  }
}

.header-image-wrapper {
	position: relative;
	height: 350px;
	overflow: hidden;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	-webkit-transition: height .25s linear;
	-o-transition: height .25s linear;
	-moz-transition: height .25s linear;
	transition: height .25s linear;
}

.header-image {
	display: block;
	height: 100%;
	position: relative;
}

.header-image img {
  max-width: 80%;
  max-height: -webkit-calc(100% - 100px);
  max-height: -moz-calc(100% - 100px);
  max-height: calc(100% - 100px);
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.nav-wrapper .inline-nav {
	margin: 7px 0 0;
	padding: 0;
	text-align: center;
}

.nav-wrapper .nav-item {
	display: inline-block;
	margin: 5px 10px;
	cursor: pointer;
}

.nav-wrapper .nav-item .label.current-page {
	padding-bottom: 5px;
	border-bottom: 2px solid;
}

#pagination a, .nav-wrapper .nav-item .label {
	color: #d17f04;
}

.nav-wrapper .nav-item .label {
	text-decoration: none;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.nav-wrapper.nav-fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
	background-color: #FFFFFF;
}

.content {
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	padding: 1px 20px;
	position: relative;
	z-index: 2;
	min-width: 270px;
}

@media screen and (min-width: 502px) {
  .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.main {
	max-width: 502px;
}

.main {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-webkit-flex: auto;
	-moz-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
	max-width: 502px;
}

article {
	margin-bottom: 30px;
	position: relative;
}

.post-wrapper {
	background: #fff;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	overflow: hidden;
	padding: 0;
	position: relative;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.08);
}

.post {
	padding: 0;
	position: relative;
	word-wrap: break-word;
	overflow: hidden;
}

.photo-wrapper {
	display: table;
	text-align: center;
	width: 100%;
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
	overflow: hidden;
}

.photo-wrapper-inner {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer;
}

.photo-wrapper-inner img {
	width: 100%;
	height: auto;
	vertical-align: middle;
}

.post-wrapper .caption {
	border-top: 1px solid #efefef;
	margin: 0;
	padding: 20px;
}

.spinner-wrapper {
	padding: 40px 50% 0px 50%;
	position: absolute;
}
